import { Alert, AlertProps } from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

export const Warning = ({ children, sx, ...rest }: AlertProps) => {
  const styles = { mb: 6, alignItems: 'center', width: '100%', ...sx };

  return (
    <Alert
      icon={<WarningRoundedIcon fontSize="medium" sx={{ color: 'white' }} />}
      sx={styles}
      {...rest}
    >
      {children}
    </Alert>
  );
};
