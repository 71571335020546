import { ReactNode } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { TextWithTooltip } from '../TextWithTooltip';

interface RowProps extends BoxProps {
  caption?: ReactNode;
  tooltip?: ReactNode;
  captionVariant?: 'secondary16' | 'description' | 'subheader1' | 'caption' | 'h3';
  captionColor?: string;
  align?: 'center' | 'flex-start';
}

export const Row = ({
  caption,
  tooltip,
  children,
  captionVariant = 'secondary16',
  captionColor,
  align = 'center',
  ...rest
}: RowProps) => {
  return (
    <Box
      {...rest}
      sx={{ display: 'flex', alignItems: align, justifyContent: 'space-between', ...rest.sx }}
    >
      {caption && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography component="div" variant={captionVariant} color={captionColor} sx={{ mr: 1 }}>
            {caption}
          </Typography>
          {tooltip && (
            <TextWithTooltip>
              <>
                {tooltip}
              </>
            </TextWithTooltip>
          )}
        </Box>
      )}
      {children}
    </Box>
  );
};
